// PostDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './PostDetails.module.css';  // Import CSS module
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../api';
import { Comments } from "@hyvor/hyvor-talk-react";
import { Helmet } from 'react-helmet';


const PostDetails = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const navigate = useNavigate();  // For redirecting after deletion
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(null); // Retrieve the token stored on login

  useEffect(() => {
    if(localStorage.getItem('access_token') === null){                   
      
  }
  else{
   setToken(localStorage.getItem('access_token')); 
    setLoggedIn(true);
  }
  }, []);

    // Function to delete the post
    const handleDelete = async () => {
      const confirmDelete = window.confirm("Are you sure you want to delete this post?");
      if (confirmDelete) {
        try {
          await axios.delete(`${API_URL}/posts/${slug}/`, {
            headers:{
              'Authorization': `Bearer ${token}`
            }
          });
          alert('Post deleted successfully');
          navigate('/');  // Redirect to homepage after deletion
        } catch (error) {
          console.error('Error deleting post:', error);
        }
      }
    };

    // update posts
    const handleUpdate = () => {
      navigate(`/posts/${slug}/update`);
    };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${API_URL}/posts/${slug}/`);
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching post details:', error);
      }
    };
    fetchPost();
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    
    <div className={styles.postCard}>
      
      <Helmet>
      <title>{post.title} | Onthir</title>
          <meta name="description" content={post.title} />
      </Helmet>
      <h1 className={styles.postTitle}>{post.title}</h1>
      {post.image || post.image_url ? (
  <div className={styles.postImage}>
    <img 
      src={post.image ? `${API_URL}${post.image}` : post.image_url} 
      alt={post.title} 
    />
  </div>
) : null}
      <div 
  className="post-details-body" 
        dangerouslySetInnerHTML={{ __html: post.body }} 
      />
{loggedIn && (
  <>
    <button onClick={handleUpdate} className="update-button">Update Post</button>
    <button onClick={handleDelete} className={styles.deleteButton}>Delete Post</button>
  </>
)}
<hr/>

    return <Comments website-id={12206} page-id={post.id} />

    </div>
    
  );
};

export default PostDetails;
